import { Link } from "gatsby"
import React from "react"

const scaigatelogo = require("../images/scaigatelogo.png")

const Footer = () => {
  return (
    <footer className="footer footer-sm">
      <div>
        <Link to="/">
          <img src={scaigatelogo} alt="" width={100} height={43} />
        </Link>
      </div>
    </footer>
  )
}

export default Footer
