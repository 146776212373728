import { Link } from "gatsby"
import React, { useState } from "react"
import { Navbar, Nav } from "react-bootstrap"

const PCSNav = () => {
  const [showDrop, setShowDrop] = useState({
    programs: false,
    events: false,
  })

  return (
    <Navbar expand="lg" fixed="top" className="boot-nav pcs">
      <Navbar.Brand to="/pcs" as={Link} className="brand">
        PCS
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="navbar-right">
          <Nav.Link to="/pcs/why-pcs" as={Link}>
            Why PCS
          </Nav.Link>

          <Nav.Link to="/pcs/projects" as={Link}>
            Projects
          </Nav.Link>

          <Nav.Link to="/pcs/community" as={Link}>
            Community
          </Nav.Link>

          <Nav.Link to="/pcs/documentation" as={Link}>
            Docs
          </Nav.Link>

          <Nav.Link to="/pcs/" as={Link}>
            Github
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default PCSNav
