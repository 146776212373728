import { Link } from "gatsby"
import React, { useState } from "react"
import { Navbar, Nav } from "react-bootstrap"
import { useLocation } from "@reach/router"
import SCAIGATENav from "./nav/scaigatenav"
import PCSNav from "./nav/pcsnav"

const HeaderSwitchCase = () => {
  const location = useLocation()

  if (location.pathname.includes("/pcs")) {
    return <PCSNav />
  }
  return <SCAIGATENav />
}

export default HeaderSwitchCase
