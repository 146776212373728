import { Link } from "gatsby"
import React, { useState } from "react"
import { Navbar, Nav } from "react-bootstrap"

const scaigate = require("../../images/scaigatelogo.png")

const SCAIGATENav = () => {
  const [showDrop, setShowDrop] = useState({
    programs: false,
    events: false,
  })

  return (
    <Navbar expand="lg" fixed="top" className="boot-nav scaigate">
      <Navbar.Brand to="/" as={Link} className="brand">
        <img width={118} height={50} src={scaigate} alt="SCAIGATE" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="navbar-right">
          <Nav.Link to="/" as={Link}>
            About
          </Nav.Link>

          <Nav.Link to="/" as={Link}>
            Login
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default SCAIGATENav
