import { Link } from "gatsby"
import React, { useState } from "react"
import { Navbar, Nav } from "react-bootstrap"
import HeaderSwitchCase from "./headerswitchcase"

interface Props {
  siteTitle: string
}

const Header = ({ siteTitle }: Props) => {
  const [showDrop, setShowDrop] = useState({
    programs: false,
    events: false,
  })

  return (
    <header>
      <div>
        <HeaderSwitchCase />
      </div>
    </header>
  )
}

export default Header
